import React from 'react'
import cn from "classnames";
import styles from './Cards.module.sass'

import { useTranslation } from 'react-i18next';

const NewCards = ({cardBg}) => {

   const { t } = useTranslation();

   const list = [
      {
         title: <span>{t("cards.first.title")}</span>,
         content: `${t("cards.first.text")}`
      },
      {
         title: <span>{t("cards.second.title")}</span>,
         content: `${t("cards.second.text")}`
      },
      {
         title: <span>{t("cards.third.title")}</span>,
         content: `${t("cards.third.text")}`
      },
   ]
   return (
      <section className={cn(styles.about)}>
         <div className="container">
            <ul className={cn(styles.list)}>
               {
                  list.map((item, index) => (
                     <li key={index} className={cn(styles["list__item"])}>
                        <div className={cn(styles["list__item__content"])} style={{background: `${cardBg} fixed`}}>
                          <h3>{item.title}</h3>
                          <p>{item.content}</p>
                        </div>
                     </li>
                  ))
               }
            </ul>
         </div>
      </section>
   )
}

export default NewCards