import React, { useRef } from 'react'
import cn from "classnames";
import styles from './NewHero.module.sass'
import emailjs from '@emailjs/browser';

import Counter from '../../../components/Counter';
import { useTranslation } from "react-i18next";


const NewHero = ({ title, description, AuxiliarContent, type, bannerBg }) => {
   const form = useRef();
  
   const { t } = useTranslation();

   const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_3m2rjcp', 'template_jl679f8', form.current, 'ssxZJkLJNZS307-Ty')
      .then((result) => {
            console.log(result.text);
      }, (error) => {
            console.log(error.text);
      });
   };
   return (
      <section className={cn(styles['hero-banner'], styles[bannerBg])}>
         <div className={cn("container", styles['container'])}>
            <div className={cn(styles['hero-banner__holder'])}>
               <div className={cn(styles['hero-banner__header'])}>
                  <div className={cn(styles['hero-banner__prelude'])}>
                     <h2 className={cn(styles['hero-banner__title'])} dangerouslySetInnerHTML={{ __html: title }} />
                     <p className={cn(styles['hero-banner__content'])}  dangerouslySetInnerHTML={{ __html: description }} />
                     <form ref={form} onSubmit={sendEmail}>
                        <div className={cn(styles['hero-banner__form'])}>
                           <input type="text" name="email" placeholder={t("banner.email.placeholder")} />
                           <input type="hidden" name="type" value={type} />
                           <button type="submit" className={cn(styles['hero-banner__form-submit'])}></button>
                        </div>
                     </form>
                  </div>
                  <Counter />
               </div>
               <AuxiliarContent />
            </div>
         </div>
      </section>
   )
}

export default NewHero