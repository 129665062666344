import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";

import { useTranslation } from "react-i18next";

import { GraphismHome, GraphismBusiness, GraphismHealth, GraphismProvider } from "./screens/Home/NewHero/graphism";

function App() {
  
  const { t } = useTranslation();
  
  return (
    <Router>
      <Switch>
        <Route
          exact
          path='/'
          render={() => (
            <Page theme="home">
              <Home 
                title={`<strong>${t("home.banner.title.highlighted")}</strong> ${t("home.banner.title.text")}`}
                description={`<span>${t("home.banner.subtitle.text")}</span> ${t("home.banner.subtitle.highlighted")}`}
                AuxiliarContent={GraphismHome}
                type="Sobre a Blue"
                bg="#FFFFFF"
                bannerBg="blue-blur"
              />
            </Page>
          )}
        />
        <Route
          exact
          path="/empresas-e-corretoras"
          render={() => (
            <Page theme="empresas">
              <Home
                title={t("business.banner.title.text")}
                description={`<span>${t("business.banner.subtitle.text")}</span> ${t("business.banner.subtitle.highlighted")}`}
                AuxiliarContent={GraphismBusiness}
                type="Empresas"
                bg={'radial-gradient(100% 165.01% at 0% 47.32%, #FEF8F0 0%, #FAF1EC 23.08%, #F3F1F3 37.06%, #F2F7F8 48%, #F2F8F9 56.82%, #FBF2FE 85.57%, #F8F0FE 95.55%, #FDF2FE 100%)'}
              />
            </Page>
          )}
        />
        <Route
          exact
          path="/planos-e-seguradoras"
          render={() => (
            <Page theme="planos-de-saude">
              <Home 
                title={t("plans.banner.title.text")}
                description={`<span>${t("plans.banner.subtitle.text")}</span> ${t("plans.banner.subtitle.highlighted")}`}
                AuxiliarContent={GraphismHealth}
                type="Planos de Saúde"
                bg="#F4F3F3"
                bannerBg="green-blur"
              />
            </Page>
          )}
        />
        <Route
          exact
          path="/provedores-de-saude"
          render={() => (
            <Page theme="provedores-de-saude">
              <Home
                title="Agora você pode oferecer seus cuidados para evitar internaçôes previstas."
                description={`<span>Cadastre o seu plano de saúde ou seguradora de saúde, ou vida, na fila e</span> comece a prever para quem mais precisa de graça por 30 dias!`}
                AuxiliarContent={GraphismProvider}
                type="Provedores de Saúde"
              />
            </Page>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
