import React from 'react'
import cn from "classnames";
import styles from './NewHero.module.sass'
import ImageMan from './person-1.png'
import ImageWomen from './person-2.png'

import ImagePeoplePt from './pt-images/home-banner-01.svg'
import ImageCardsPt from './pt-images/home-banner-02.svg'
import ImageInfoPt from './pt-images/home-banner-03.svg'
// import ImagePeopleEn from './pt-images/home-banner-01.svg'
// import ImageCardsEn from './pt-images/home-banner-02.svg'
// import ImageInfoEn from './pt-images/home-banner-03.svg'

// import { useTranslation } from "react-i18next";

const style = { width: '100%', maxWidth: '600px' }

export const GraphismHome1 = () => {
   return (
      <div className={cn(styles['hero-banner__graphism'])}>
         <ul className={cn(styles['hero-banner__graphism-list'])}>
            <li className={cn(styles['hero-banner__graphism-item'])} style={{ backgroundImage: `url(${ImageMan})` }}>
               <div className={cn(styles['hero-banner__graphism-head'])}>
               <span className={cn(styles['hero-banner__graphism-content'], styles['hero-banner__graphism-content--small'])}>Prevendo para</span>
                  <span className={cn(styles['hero-banner__graphism-title'])}>Nicolle Dias</span>
                  <div className={cn(styles['hero-banner__graphism-group'])}>
                     <span className={cn(styles['hero-banner__graphism-content'], styles['hero-banner__graphism-value'])}>14%</span>
                     <span className={cn(styles['hero-banner__graphism-content'])}>Cirurgia da Tireóide</span>
                  </div>
               </div>
            </li>
            <li className={cn(styles['hero-banner__graphism-item'], styles['hero-banner__graphism-item--middle'])} style={{ backgroundImage: `url(${ImageWomen})` }}>
               <div className={cn(styles['hero-banner__graphism-head'])}>
               <span className={cn(styles['hero-banner__graphism-content'], styles['hero-banner__graphism-content--small'])}>Prevendo para</span>
                  <span className={cn(styles['hero-banner__graphism-title'])}>Lucas Nery</span>
                  <div className={cn(styles['hero-banner__graphism-group'])}>
                     <span className={cn(styles['hero-banner__graphism-content'], styles['hero-banner__graphism-value'])}>14%</span>
                     <span className={cn(styles['hero-banner__graphism-content'])}>Cirurgia do Rim</span>
                  </div>
               </div>
            </li>
         </ul>
      </div>
   )
}

export const GraphismHome = () => {
  return <img style={{...style, maxWidth: '765px', position: 'absolute', top: '-50px', right: 0}} src={ImagePeoplePt} alt="blueai" />
}

export const GraphismBusiness = () => {
   return <img style={{...style, maxWidth: '740px', position: 'absolute', top: '-80px', right: '-50px'}} src={ImageCardsPt} alt="blueai" />
}

export const GraphismHealth = () => {
   return <img style={{...style, maxWidth: '500px', position: 'absolute', top: '-80px', right: '60px'}} src={ImageInfoPt} alt="blueai" />
}

export const GraphismProvider = () => {
   return <img style={style} src={ImageInfoPt} alt="blueai" />
}