import React from "react";
import cn from "classnames";
import styles from './Counter.module.sass'

import tagIcon01 from './tagIcon-01.svg'
import tagIcon02 from './tagIcon-02.svg'
import tagIcon03 from './tagIcon-03.svg'
import starDetail from './star-detail.svg'
import { useTranslation } from "react-i18next";

const Counter = () => {
  
  const { t } = useTranslation();

  return(
    <div className={cn(styles.counter)}>
      <div className={cn(styles.counterTitle)}>
        <p>
          {t("banner.counter.title")}
        </p>
      </div>

      <div className={cn(styles.counterTagList)}>
        <div className={cn(styles.counterTag)}>
          <div>
            <p className={cn(styles.tagTitle)}>10</p>
            <span className={cn(styles.tagIcon)}>
              <img src={tagIcon01} width="12px" height="16px" alt="icon"/>
            </span>
          </div>
          <span className={cn(styles.tagText)}>{t("banner.counter.tags.first")}</span>
        </div>
        
        <span className={cn(styles.starDetail)}>
          <img src={starDetail} alt="icon star detail"/>
        </span>

        <div className={cn(styles.counterTag)}>
          <div>
            <p className={cn(styles.tagTitle)}>8</p>
            <span className={cn(styles.tagIcon)}>
              <img src={tagIcon02} width="15px" height="10px" alt="icon"/>
            </span>
          </div>
          <span className={cn(styles.tagText)}>{t("banner.counter.tags.second")}</span>
        </div>
        
        <span className={cn(styles.starDetail)}>
          <img src={starDetail} alt="icon star detail"/>
        </span>

        <div className={cn(styles.counterTag)}>
          <div>
            <p className={cn(styles.tagTitle)}>12</p>
            <span className={cn(styles.tagIcon)}>
              <img src={tagIcon03} width="16px" height="16px" alt="icon"/>
            </span>
          </div>
          <span className={cn(styles.tagText)}>{t("banner.counter.tags.third")}</span>
        </div>
      </div>
    </div>
  );
};

export default Counter;