import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./assets/translations/en.json";
import translationPT from "./assets/translations/pt.json";

const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};



i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    resources,
    fallbackLng: "pt",
    debug: true,
    lng: "pt",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false
    },
  });


export default i18n;