import React from "react";
import NewCards from "./NewCards";
import NewHero from "./NewHero";
import styles from "./Home.module.sass";

const Home = ({ title, description, theme, cards, bannerBg, AuxiliarContent, type, bg }) => {
  return (
    <div className={styles.homeWrapper} style={{background: `${bg} fixed`}}>
      <NewHero title={title} description={description} AuxiliarContent={AuxiliarContent} type={type} bannerBg={bannerBg} />
      <NewCards cardBg={bg}/>
    </div>
  );
};

export default Home;
