import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../Form";
// import Theme from "../Theme";
import { useTranslation } from "react-i18next";

const Footers = () => {
  const [email, setEmail] = useState("");

  const { t } = useTranslation();
  
  const items = [
    {
      title: t("footer.section.title"),
      menu: [
        {
          title: t("footer.section.page_1"),
          url: "/empresas-e-corretoras",
        },
        {
          title: t("footer.section.page_2"),
          url: "/planos-e-seguradoras",
        },
      ],
    },
  ];

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/logo.svg"
                srcDark="/images/logo.svg"
                alt="Fitness Pro"
              />
            </Link>
            <div className={styles.info}>{t("footer.title")}</div>
            {/* <div className={styles.version}>
              <div className={styles.details}>Dark theme</div>
              <Theme className="theme-big" />
            </div> */}
          </div>
          <div className={styles.col}>
            {items.map((x, index) => (
              <Group className={styles.group} item={x} key={index} />
            ))}
          </div>
          <div className={styles.col}>
            <div className={styles.category}>{t("footer.contact.title")}</div>
            <div className={styles.text}>
              {t("footer.contact.subtitle")}
            </div>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={() => handleSubmit()}
              placeholder={t("footer.contact.placeholders.email")}
              type="email"
              name="email"
            />
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.copyright}>
            Copyright © 2022 Blue AI. All rights reserved
          </div>
          <div className={styles.note}>
            We use cookies for better service. <a href="/#">Accept</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
