import React from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Icon from "../Icon";
import { t } from "i18next";

const Form = ({
  className,
  onSubmit,
  placeholder,
  value,
  setValue,
  type,
  name,
}) => {
  return (
    <form className={cn(styles.form, className)} action="https://blueai.us12.list-manage.com/subscribe/post?u=b620fd566cf986f4b139462f0&amp;id=13bc09582e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" onSubmit={onSubmit}>
      <textarea
        className={styles['input--big']}
        type="text"
        onChange={(e) => setValue(e.target.value)}
        name="MESSAGE"
        placeholder={t("footer.contact.placeholders.message")}
        required
        resizable="false"
        id="mce-MESSAGE"
      />
      <input
        className={styles.input}
        type={type}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        required
        name="EMAIL" 
        id="mce-EMAIL"
      />
      <button className={styles.btn}>
        <Icon name="arrow-next" size="14" />
      </button>
    </form>
  );
};

export default Form;
