import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Image from "../Image";

import { useTranslation } from "react-i18next";

const nav = [
  {
    url: "/empresas-e-corretoras",
    title: "Para empresas e corretoras",
  },
  {
    url: "/planos-e-seguradoras",
    title: "Para planos de saúde e seguradoras",
  },
  {
    url: "/item",
    title: "Para provedores de saúde",
  }
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo.svg"
            srcDark="/images/logo.svg"
            alt="Fitness Pro"
          />
        </Link>
        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            <Link
              className={styles.link}
              to="/empresas-e-corretoras"
            >
              {t("header.navigation.firstPage")}
            </Link>
            <Link
              className={styles.link}
              to="/planos-e-seguradoras"
            >
              {t("header.navigation.secondPage")}
            </Link>
          </nav>
          <Link
            className={cn("button-small", styles.button)}
            to="/upload-variants"
          >
            Pedir convite
          </Link>
        </div>
        <Link
          className={cn("button-small", "button-stroke")}
          to={{ pathname: "https://app.blueai.com.br" }}
          target="_blank"
        >
          {t("header.login")}
        </Link>
        <div className={cn(styles.langSelector)}>
          <p onClick={() => changeLanguage('en')}>EN</p>
          <p onClick={() => changeLanguage('pt')}>PT</p>
        </div>
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
